<template>
  <div class="mt-4">
    <!--<h1 class="h5 font-weight-normal mb-3">Main Menu</h1>-->

    <div class="text-center">
      
        <button
          type="button"
          class="btn btn-primary btn-block"
          @click="$router.push('/operations')"
        >
          <!--<i class="fa fa-wrench" aria-hidden="true"></i>--> Tools
        </button>
        <!--<button
          type="button"
          class="btn btn-primary btn-block"
          @click="$router.push('/workspaces')"
        > Automation
        </button>-->

        <!--<button
          type="button"
          class="btn btn-secondary"
          @click="$router.push('/football')"
        >
          Content to Paste
        </button>
        <button
          type="button"
          class="btn btn-secondary"
          @click="$router.push('/fileUpload')"
        >
          File Upload
        </button>
        <button
          type="button"
          class="btn btn-secondary"
          @click="$router.push('/help')"
        >
          Help
        </button>-->
        <!--<button
          type="button"
          class="btn btn-secondary"
          @click="$router.push('/patientNarratives')"
        >
          Patient Narratives
        </button>-->
    </div>

    <!--<button @click="clickme()">Click Me</button>-->
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  methods: {
    async clickme() {
      console.log("clickme function called");
      let result = "<h1>Hello Manoloñ</h1>";
      return Word.run(async (context) => {
        /**
         * Insert your Word code here
         */

        // insert a paragraph at the end of the document.
        //const paragraph = context.document.body.insertParagraph(result, Word.InsertLocation.end);
        const paragraph = context.document.body.insertHtml(
          result,
          Word.InsertLocation.end
        );

        // change the paragraph color to blue.
        paragraph.font.color = "blue";

        await context.sync();
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.btn-group-vertical > .btn {
  margin-bottom: 10px;
}
</style>
