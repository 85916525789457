<template>
  <tr v-if="!confirmationOn">
    <td style="width: 50%; word-wrap: break-word">
      <a
        @click="downloadResultFile()"
        tabindex="0"
        role="button"
        class="link"
        title="Download in a new Word document"
      >
        {{ fileToDelete.name }}</a
      >
    </td>
    <td style="width: 20%">
      <button
        class="btn btn-warning btn-sm float-right"
        @click="pasteContentIntoWordAtCursor()"
        title="Download in a new Word document"
      >
        Paste
      </button>
    </td>
    <td class="text-right" style="width: 10%">
      <!--{{item.date}}-->
      <button
        class="btn btn-danger btn-sm float-right"
        @click="toggleConfirmation()"
        title="Remove this file"
      >
        -
      </button>
    </td>
  </tr>

  <tr v-else>
    <td colspan="3">
      <div style="width: 50%; float: left">
        <strong>Confirm deletion?</strong>
      </div>
      <div style="float: left; text-align: right; width: 50%">
        <button
          @click="toggleConfirmation()"
          class="btn btn-success btn-sm float-right"
        >
          Cancel
        </button>
        <button @click="deleteFile()" class="btn btn-danger btn-sm float-right">
          Yes
        </button>
      </div>
    </td>
  </tr>
</template>

<script>
import Files from "../api/files.js";

export default {
  name:"WorkspaceFileResultRemoveButton",
  props: {
    userId: Number,
    workspaceId: String,
    fileToDelete: Object,
  },
  data: function () {
    return {
      confirmationOn: false,
    };
  },
  methods: {
    toggleConfirmation() {
      this.confirmationOn = !this.confirmationOn;
    },
    async pasteContentIntoWordAtCursor(){
      this.$emit("onPasteContentIntoWordAtCursor", this.fileToDelete.name);
    },
    async downloadResultFile(){
      this.$emit("onDownloadResultFile", this.fileToDelete.name);
    },
    async deleteFile() {
      this.$emit("onDelete", this.fileToDelete.name);
    },
  },
};
</script>
<style scoped>
.nrtlabel {
  font-size: 0.7em;
}

#uploadFilesButton {
  z-index: 9999;
  background-color: #218838;
  color: white;
  cursor: pointer;
  font-size: 0.875rem;
}

#the_file {
  color: transparent;
}

.custom-file {
  font-size: 0.875rem;
}
</style>