<template>
  <div>
    <button
      @click="getCSRNarratives()"
      class="btn btn-primary btn-block mtb-3 mb-4 btn-sm"
      :disabled="loading"
    >
      <span v-if="!loading">Generate CSR Narratives</span>
      <span v-else>Working... this might take a while</span>
    </button>
    <p v-if="this.errorMsg" class="text-center">Error: {{ this.errorMsg }}</p>

    <!--<h2 class="h6" v-if="this.csrNarratives && this.csrNarratives.length>0">{{this.csrNarratives.length}} generated</h2>
    <div v-for="(item, key) in this.csrNarratives" :key="key" class="mb-4">
      <h2 class="h5">Subject: {{ item.subject }}</h2>
      <p class="text-area">
        <span v-html="item.narrative"></span>
      </p>
      <p class="text-right">
      <button
        @click="pasteIntoWordAtCursor(item.narrative)"
        class="btn btn-primary btn-sm"
      >
        Paste
      </button>
      </p>
    </div>-->
  </div>
</template>

<script>
import CSRNarrativesProvider from "../api/csrNarrativesProvider.js";
import { wordHandlerMixin } from "./mixins/wordHandlerMixin.js";

export default {
  name: "CSRNarrativesWorkspace",
  mixins: [wordHandlerMixin],
  data: function () {
    return {
      loading: false,
      error: false,
      errorMsg: "",
      csrNarratives: null,
    };
  },
  props: {
    workspaceId: String,
  },
  mounted() {
    //this.csrNarratives = this.getCSRNarratives();
  },
  methods: {
    async getCSRNarratives() {
      this.loading = true;
      this.error = false;
      const [data, err] =
        await CSRNarrativesProvider.getCSRNarrativesForWorkspace(
          this.$store.getters.user.id,
          this.$route.params.workspaceId
        );

      if (err) {
        console.log("Error getCSRNarratives:" + err.data.error);
        this.errorMsg = err.data.error;
        this.csrNarratives = null;
        this.error = true;
        this.loading = false;
        return;
      } else {
        this.csrNarratives = data;
        console.log("CSR narratives asigned");
        this.loading = false;
        setTimeout(() => {
          this.$emit("refreshFiles", 1);
        }, 100);

        return data["result"];
      }
    },
  },
};
</script>
<style scoped>
.text-area {
  border: 1px solid black;
  text-align: left !important;

  margin-top: 1em;
  height: 7em;
  overflow-y: scroll;
  background-color: white;
  font-size: 0.75em;
}

.nrtlabel {
  font-size: 0.7em;
}
</style>