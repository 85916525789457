import Vue from "vue";
import Router from "vue-router";
import Home from "@/components/Home.vue";
import Login from "@/components/Login.vue";
import RecoverPassword from "@/components/RecoverPassword.vue";
import MainWrapper from "@/components/MainWrapper.vue";
//import TenseSwitcher from "@/components/TenseSwitcher.vue"
import TenseSwitcherOnlySelection from "@/components/TenseSwitcherOnlySelection.vue"
import PatientNarratives from "@/components/PatientNarratives.vue"
import ClinicalTrials from "@/components/ClinicalTrials.vue"

import Workspaces from "@/components/Workspaces.vue"
import Operations from "@/components/Operations.vue"
import Workspace from "@/components/Workspace.vue"

Vue.use(Router);

let router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      redirect:"/login",
      component: {
          template: '<router-view/>'
      }
    },
    {
      //This index.html is because in the manifest.xml the URL of the plugin is blabla/index.html and should be blabla/
      path: "/index.html",
      redirect:"/login",
      component: {
          template: '<router-view/>'
      }
    },
    {
      path: "/login",
      name: "login",
      component: Login
    },
    /*{
      path: "/recoverPassword",
      name: "recoverPassword",
      component: MainWrapper,
      children: [
        { path: '', component: RecoverPassword }
      ]
    },*/
    {
      path: "/home",
      name: "home",
      component: Home

    },
    {
      path: "/operations",
      name: "operations",
      component: Operations
    },
    {
      path: "/tenseSwitcher",
      component: MainWrapper,
      children: [
        { path: '', component: TenseSwitcherOnlySelection }
      ]
    },
    {
      path: "/patientNarratives",
      component: MainWrapper,
      children: [
        { path: '', component: PatientNarratives }
      ]

    },
    {
      path: "/clinicalTrials",
      component: MainWrapper,
      children: [
        { path: '', component: ClinicalTrials }
      ]

    },
    {
      path: "/workspaces",
      component: MainWrapper,
      children: [
        { path: '', component: Workspaces }
      ]

    },
    {
      path: "/workspace",
      component: MainWrapper,
      children: [
        { path: ':workspaceId', component: Workspace }
      ]

    }
  ]
});

export default router;
