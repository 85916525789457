<template>
  <tr v-if="!confirmationOn">
    <td style="width: 85%; word-wrap: break-word" >
      <router-link :to="this.baseUrl + this.workspaceToDelete.id" href="#">{{
        this.workspaceToDelete.name
      }}</router-link>
    </td>



    <td class="text-right">
      <button
        v-if="!confirmationOn"
        class="btn btn-danger btn-sm float-right"
        @click="toggleConfirmation()"
        title="Remove this file"
      >
        -
      </button>
    </td>
  </tr>
  <tr v-else>
    <td colspan="2">
      <div style="width: 50%; float: left">
        <strong>Confirm deletion?</strong>
      </div>
      <div style="float: left; text-align: right; width: 50%">
        <button
          @click="toggleConfirmation()"
          class="btn btn-success btn-sm float-right"
        >
          Cancel
        </button>
        <button
          @click="deleteWorkspace()"
          class="btn btn-danger btn-sm float-right"
        >
          Yes
        </button>
      </div>
    </td>
  </tr>
</template>

<script>

export default {
  name:"WorkspaceRemoveButton",
  props: {
    userId: Number,
    workspaceId: String,
    baseUrl: String,
    workspaceToDelete: Object,
  },
  data: function () {
    return {
      confirmationOn: false,
    };
  },
  methods: {
    toggleConfirmation() {
      this.confirmationOn = !this.confirmationOn;
    },
    async deleteWorkspace() {
      this.$emit("onDeleteWorkspace", this.workspaceToDelete.id);
    },
  },
};
</script>
<style scoped>
.nrtlabel {
  font-size: 0.7em;
}

#uploadFilesButton {
  z-index: 9999;
  background-color: #218838;
  color: white;
  cursor: pointer;
  font-size: 0.875rem;
}

#the_file {
  color: transparent;
}

.custom-file {
  font-size: 0.875rem;
}
</style>