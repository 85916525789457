var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',{staticClass:"h5 font-weight-normal mb-2"},[_vm._v("Automation")]),(_vm.loading)?_c('p',{staticClass:"text-center"},[_vm._v("Loading...")]):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[(!this.showInsertWorkspaces)?_c('button',{staticClass:"btn btn-sm mb-2 btn-success",attrs:{"id":"addWorkspaceButton"},on:{"click":function (data) {
            return (this$1.showInsertWorkspaces = !this$1.showInsertWorkspaces);
          }}},[(!this.showInsertWorkspaces)?_c('span',[_vm._v(" + New Workspace")]):_c('span',[_c('i',{staticClass:"fa fa-minus-circle",attrs:{"aria-hidden":"true"}}),_vm._v(" Close")])]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showInsertWorkspaces),expression:"showInsertWorkspaces"}],staticClass:"input-group mb-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newWorkspaceName),expression:"newWorkspaceName"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"New workspace name","aria-label":"New workspace name"},domProps:{"value":(_vm.newWorkspaceName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.newWorkspaceName=$event.target.value}}}),_c('div',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-success btn-sm",attrs:{"type":"button"},on:{"click":_vm.addNewWorkspace}},[_vm._v(" Add ")]),_c('button',{staticClass:"btn btn-warning btn-sm",attrs:{"type":"button"},on:{"click":function (data) {
                return (this$1.showInsertWorkspaces =
                  !this$1.showInsertWorkspaces);
              }}},[_vm._v(" Close ")])])])])]),(this.workspaces && this.workspaces.length >= 0)?_c('div',[(this.workspaces.length && this.workspaces.length > 0)?_c('table',{staticClass:"table table-striped table-sm"},_vm._l((this.workspaces),function(workspace,key){return _c('WorkspaceRemoveButton',{key:key,attrs:{"workspaceToDelete":workspace,"userId":_vm.$store.getters.user.id,"baseUrl":_vm.base_url},on:{"onDeleteWorkspace":_vm.deleteWorkspace}})}),1):(!this.loading)?_c('div',{staticClass:"mt-4 mb-4"},[_vm._v(" No workspaces found. Add one to start working ")]):_vm._e()]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }