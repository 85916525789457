<template>
  <div class="mt-4">
    <form class="form-signin" @submit="login">
      <h1 class="h4 mb-3 font-weight-normal">Recover Password</h1>
      <div class="row">
        <div class="col">
          <p>If you forgot your password, write your email below. We will send the reset instructions to you.</p>
        </div>
      </div>
      <label for="inputEmail" class="sr-only">Email address</label>
      <input
        type="email"
        id="inputEmail"
        class="form-control"
        placeholder="Email address"
        required=""
        autofocus=""
        value="test@pharma.com"
        v-model="credentials.email"
      />
      <div v-if="error" class="error text-center mt-4">
        {{ this.errorMsg }}
      </div>
      <button class="btn btn-lg btn-primary btn-block mt-4" type="submit">
        Sign in <!--<i class="fa fa-sign-in" aria-hidden="true"></i>-->
      </button>
    </form>
  </div>
</template>

<script>
import Users from "../api/users.js";
const USER_INACTIVE = "user_inactive";

export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  computed: {
    isLoggedIn() {
      let logged = this.$store.getters.isLoggedIn;
      console.log("Logged?" + logged)
      return logged;
    },
  },
  mounted() {
    let isLogged = this.isLoggedIn
    console.log("isLogged:" + isLogged)
    if (this.isLoggedIn) {
      console.log("User already logged");
      this.$router.push("/home");
    }else{
      console.log("NOT LOGGED")
    }


  },
  data: function () {
    return {
      loading: false,
      error: null,
      errorMsg: "",
      credentials: {
        email: "",
        password: "",
        remember: false,
      },
    };
  },
  methods: {
    async login(e) {
      e.preventDefault();

      this.error = null;
      this.loading = true;

      const { email, password, remember } = this.credentials;
      const body = { email, password, remember };

      const [data, err] = await Users.login(body);

      this.loading = false;

      if (err) {
        console.log("login error:" + err.data);
        this.error = true;
        this.errorMsg = err.data.error
        return;
      }

      const { status, message } = data;

      switch (status) {
        case USER_INACTIVE:
          this.$refs.verify.setEmail(email);
          this.showResend = true;
          return;
      }

      if (status == "failure") {
        this.error = message;
        return;
      }

      this.$store.dispatch("logInUser", data);
      this.$router.push("/home");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.error {
  font-size: 1em;
  color: red;
}
</style>
