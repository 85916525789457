import Endpoint from './endpoint'
import bindAll from 'lodash.bindall'

class WorkspacesProvider extends Endpoint {
  constructor() {
    let url = 'api/users';
    super(url);
    bindAll(this, ['getListOfWorkspacesForUser']);
  }
  getListOfWorkspacesForUser(userId) {
    return this.get(`/${userId}/workspaces`);
  }
  
  getWorkspaceById(userId,workspaceId) {
    return this.get(`/${userId}/workspaces/${workspaceId}`);
  }

  addNewWorkspace(userId,data) {
    return this.post(`/${userId}/workspaces`,data);
  }
  deleteWorkspace(userId,workspaceId) {
    return this.delete(`/${userId}/workspaces/${workspaceId}`);
  }
}

export default new WorkspacesProvider()