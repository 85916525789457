<template>
  <div>
    <!-- main wrapper -->
    <a
      @click="$router.go(-1)"
      class="btn btn-sm btn-outline-secondary mt-2"
      id="backButton"
      >&lt;&lt; Back
    </a>
    <div class="mt-3 mb-2">
      <router-view></router-view>
    </div>
    <!-- main wrappter end -->
  </div>
</template>
<script>


export default {
  name: "MainWrapper",
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
  },
    mounted() {
    if (!this.isLoggedIn) {
      console.log("User not logged. Redirect to login page");
      this.$router.push("/login");
    }
  }
}
</script>
<style scoped>
#backButton {
  font-size: 0.65em;
}
</style>