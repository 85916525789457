const getToken = () => (localStorage.getItem('token'))

const getUser = () => {
  const user = localStorage.getItem('user')
  return JSON.parse(user)
}

const state = {
  session: {
    isLoggedIn: getToken() != null,
    user: getUser(),
    studies_and_sections: []
  }
}

export default state