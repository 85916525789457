import Endpoint from './endpoint'
import bindAll from 'lodash.bindall'

class Users extends Endpoint {
  constructor() {
    let url = 'api/users';
    super(url);
    bindAll(this, ['login']);
  }
  login(body) {
    return this.post('/login', body);
  }

}


export default new Users()