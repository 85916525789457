import Endpoint from './endpoint'
import bindAll from 'lodash.bindall'

class Files extends Endpoint {
  constructor() {
    let url = 'api';
    super(url);
    bindAll(this, ['getListOfFiles','getListOfFilesForWorkspaceAndUser']);
  }
  getListOfFiles() {
    return this.get(`/files`);
  }
  getListOfFilesForWorkspaceAndUser(userId,workspaceId) {
    return this.get(`/users/${userId}/workspaces/${workspaceId}/files`);
  }

  uploadFilesForWorkspaceAndUser(userId,workspaceId,files) {
    return this.post(`/users/${userId}/workspaces/${workspaceId}/files/upload`,files);
  }

  deleteFiles(userId,workspaceId,fileSemicolonSepparated){
    return this.delete(`/users/${userId}/workspaces/${workspaceId}/files/${fileSemicolonSepparated}`);
  }

  downloadFile(userId,workspaceId,fileName){
    return this.get(`/users/${userId}/workspaces/${workspaceId}/files/${fileName}`,{responseType: 'blob'});
  }
}


export default new Files()