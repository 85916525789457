<template>
  <div>
    <NavHead></NavHead>
    <div id="app" class="container-fluid">
      <router-view />
    </div>
    <!-- container -->
  </div>
</template>

<script>
import NavHead from "./components/NavHead.vue";
export default {
  name: "App",
  components: {
    NavHead,
  },
};
</script>

