<template>
  <div>
    <h4>Clinical Trials</h4>

    <button
      @click="getClinicalTrials()"
      class="btn btn-primary btn-sm mtb-3 mb-4"
    >
      Download Clinical Trial
    </button>
    <p v-if="loading" class="text-center">Working... this might take a while</p>

    <div v-if="clinical_trial">
      <p class="text-area">
        <span v-html="clinical_trial.result"></span>
      </p>
      <button
        @click="pasteIntoWordAtCursor(clinical_trial.result)"
        class="btn btn-primary btn-sm"
      >
        Paste at cursor
      </button>
    </div>
  </div>
</template>

<script>
import ClinicalTrialsProvider from "../api/clinicalTrialsProvider.js";
import { wordHandlerMixin } from "./mixins/wordHandlerMixin.js";

export default {
  name: "ClinicalTrials",
  mixins: [wordHandlerMixin],
  data: function () {
    return {
      loading: false,
      error: false,
      clinical_trial: null,
    };
  },
  mounted() {},
  methods: {
    async getClinicalTrials() {
      this.loading = true;
      let sentence = this.text_area_value;
      let body = { sentence: sentence };
      const [data, err] = await ClinicalTrialsProvider.getClinicalTrials(
        "123",
        null
      );

      if (err) {
        console.log("Error getClinicalTrials:" + err);
        this.errorMsg = err;
        this.error = true;
        this.loading = false;
        return;
      } else {
        this.clinical_trial = data;
        console.log("clinical trials result asigned");
        this.loading = false;
        return data["result"];
      }
    },
  },
};
</script>
<style scoped>
.text-area {
  border: 1px solid black;
  text-align: left !important;

  margin-top: 1em;
  /*height: 10em;*/
  /*overflow-y: scroll;
  overflow-x: scroll;*/
  background-color: white;
}

.nrtlabel {
  font-size: 0.7em;
}
</style>