<template>
  <div>
    <h1 class="h5 font-weight-normal mb-2">Automation</h1>
    <p v-if="loading" class="text-center">Loading...</p>
    <div class="row">
      <div class="col">
        <button
          v-if="!this.showInsertWorkspaces"
          class="btn btn-sm mb-2 btn-success"
          @click="
            (data) => {
              return (this.showInsertWorkspaces = !this.showInsertWorkspaces);
            }
          "
          id="addWorkspaceButton"
        >
          <span v-if="!this.showInsertWorkspaces"
            ><!--<i class="fa fa-plus-circle" aria-hidden="true"></i>-->
            + New Workspace</span
          >
          <span v-else
            ><i class="fa fa-minus-circle" aria-hidden="true"></i> Close</span
          >
        </button>

        <div class="input-group mb-3" v-show="showInsertWorkspaces">
          <input
            type="text"
            v-model="newWorkspaceName"
            class="form-control"
            placeholder="New workspace name"
            aria-label="New workspace name"
          />
          <div class="input-group-append">
            <button
              class="btn btn-success btn-sm"
              type="button"
              @click="addNewWorkspace"
            >
              Add
            </button>
            <button
              class="btn btn-warning btn-sm"
              type="button"
              @click="
                (data) => {
                  return (this.showInsertWorkspaces =
                    !this.showInsertWorkspaces);
                }
              "
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>

    <div v-if="this.workspaces && this.workspaces.length >= 0">
      <table
        class="table table-striped table-sm"
        v-if="this.workspaces.length && this.workspaces.length > 0"
      >
        <WorkspaceRemoveButton
          v-for="(workspace, key) in this.workspaces"
          :key="key"
          :workspaceToDelete="workspace"
          :userId="$store.getters.user.id"
          :baseUrl="base_url"
          @onDeleteWorkspace="deleteWorkspace"
        >
        </WorkspaceRemoveButton>
      </table>
      <div v-else-if="!this.loading" class="mt-4 mb-4">
        No workspaces found. Add one to start working
      </div>
    </div>
  </div>
</template>

<script>
import WorkspaceRemoveButton from "./WorkspaceRemoveButton.vue";
import WorkspacesProvider from "../api/workspacesProvider.js";
import { wordHandlerMixin } from "./mixins/wordHandlerMixin.js";


export default {
  name: "Workspaces",
  mixins: [wordHandlerMixin],
  components:{
    WorkspaceRemoveButton
  },
  data: function () {
    return {
      loading: false,
      error: false,
      workspaces: null,
      newWorkspaceName: "",
      showInsertWorkspaces: false,
      base_url: "/workspace/",
    };
  },
  mounted() {
    this.workspaces = this.getWorkspaces();
  },
  methods: {
    async getWorkspaces() {
      this.loading = true;

      const [data, err] = await WorkspacesProvider.getListOfWorkspacesForUser(
        this.$store.getters.user.id
      );

      if (err) {
        console.log("Error getWorkspaces:" + err);
        this.loading = false;
        return;
      } else {
        this.workspaces = data;
        console.log("getWorkspaces ok");
        this.loading = false;
        return data["result"];
      }
    },
    async addNewWorkspace() {
      this.loading = true;
      let name = this.newWorkspaceName;
      let description = "test desc";
      let attached = { name, description };
      console.log(attached);
      const [data, err] = await WorkspacesProvider.addNewWorkspace(
        this.$store.getters.user.id,
        attached
      );

      if (err) {
        console.log("Error addNewWorkspace:" + err);
        console.log(data);
        this.loading = false;
        return;
      }
      this.newWorkspaceName = "";
      await this.getWorkspaces();
      return;
    },
    async deleteWorkspace(workspaceId) {
      this.loading = true;
      const [data, err] = await WorkspacesProvider.deleteWorkspace(
        this.$store.getters.user.id,
        workspaceId
      );

      if (err) {
        console.log("Error deleteWorkspace:" + err);
        console.log(data);
        this.loading = false;
        return;
      }
      console.log("deleteWorkspace ok");

      await this.getWorkspaces();
      return;
    },
  },
};
</script>
<style scoped>
.nrtlabel {
  font-size: 0.7em;
}

table {
  width: 100%;
}
</style>