import Endpoint from './endpoint'
import bindAll from 'lodash.bindall'

class CSRSec12NarrativesProvider extends Endpoint {
  constructor() {
    let url = 'api/csr';
   
    super(url);
    bindAll(this, ['getCSRNarrativesSafetyLabForWorkspace','getCSRNarrativesSafetyOtherFindingsLabForWorkspace']);
  }

  getCSRNarrativesSafetyLabForWorkspace(userId,workspaceId) {
    return this.get(`/users/${userId}/workspaces/${workspaceId}/sdtm/safety/lab`);
  }

  getCSRNarrativesSafetyOtherFindingsLabForWorkspace(userId,workspaceId) {
    return this.get(`/users/${userId}/workspaces/${workspaceId}/sdtm/safety/other_findings`);
  }

  getCSRNarrativesSafetyAdverseEventsForWorkspace(userId,workspaceId) {
    return this.get(`/users/${userId}/workspaces/${workspaceId}/sdtm/safety/adverse_events`);
  }

  
}

export default new CSRSec12NarrativesProvider()