import Endpoint from './endpoint'
import bindAll from 'lodash.bindall'

class TenseSwitcherProvider extends Endpoint {
  constructor() {
    let url = 'api/tense_switcher_word';
   
    super(url);
    bindAll(this, ['getSentenceSwitched']);
  }
  getSentenceSwitched(body) {
    return this.post('', body);
  }
}


export default new TenseSwitcherProvider()