<template>
  <div>

    <button
      @click="getPatientNarratives()"
      class="btn btn-primary btn-block mtb-3 mb-4 btn-sm"
      :disabled="loading"
    >
      <span v-if="!loading">Generate Patient Narratives</span>
      <span v-else>Working... this might take a while</span>
    </button>
    <p v-if="this.errorMsg" class="text-center">Error: {{this.errorMsg}}</p>

  </div>
</template>

<script>
import PatientNarrativesProvider from "../api/patientNarrativesProvider.js";
import { wordHandlerMixin } from "./mixins/wordHandlerMixin.js";

export default {
  name: "PatientNarrativesWorkspace",
  mixins: [wordHandlerMixin],
  data: function () {
    return {
      loading: false,
      error: false,
      errorMsg:"",
      patientNarratives: null,
    };
  },
  props: {
    workspaceId: String,
  },
  mounted() {
    //this.patientNarratives = this.getPatientNarratives();
  },
  methods: {
    async getPatientNarratives() {
      this.loading = true;
      this.error = false;
      const [data, err] = await PatientNarrativesProvider.getPatientNarrativesForWorkspace(
        this.$store.getters.user.id,
        this.$route.params.workspaceId
      );

      if (err) {
        console.log("Error getPatientNarratives:" + err.data.error);
        this.errorMsg = err.data.error;
        this.patientNarratives = null;
        this.error = true;
        this.loading = false;
        return;
      } else {
        this.patientNarratives = data;
        console.log("Patient narratives asigned");
        this.loading = false;
        setTimeout(() => { this.$emit('refreshFiles',1) }, 100);
        
        
        return data["result"];
      }
    },
  },
};
</script>
<style scoped>
.text-area {
  border: 1px solid black;
  text-align: left !important;

  margin-top: 1em;
  height: 7em;
  overflow-y: scroll;
  background-color: white;
  font-size: 0.75em;
}

.nrtlabel {
  font-size: 0.7em;
}
</style>