import Endpoint from './endpoint'
import bindAll from 'lodash.bindall'

class CSRSec10NarrativesProvider extends Endpoint {
  constructor() {
    let url = 'api/csr';
    //let url = 'api/csr_fake';
   
    super(url);
    bindAll(this, ['getCSRNarratives','getCSRNarrativesDispositionForWorkspace','getCSRNarrativesDemographicsForWorkspace']);
  }
  getCSRNarratives(sectionId) {
    return this.get(`/${sectionId}`);
  }

  getCSRNarrativesDispositionForWorkspace(userId,workspaceId) {
    return this.get(`/users/${userId}/workspaces/${workspaceId}/disposition`);
  }

  getCSRNarrativesDemographicsForWorkspace(userId,workspaceId) {
    return this.get(`/users/${userId}/workspaces/${workspaceId}/demographics`);
  }

  getCSRNarrativesDeviationsForWorkspace(userId,workspaceId) {
    return this.get(`/users/${userId}/workspaces/${workspaceId}/deviations`);
  }
}

export default new CSRSec10NarrativesProvider()