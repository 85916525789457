<template>
  <div>
    <h1 class="h5 font-weight-normal mb-3">Tense Switcher</h1>
    <div class="text-justify mb-4">
      Select text in the document, then press the button to transform selected text to past tense.
    </div>
    <div class="text-center">
      
      <button v-if="loading"
        @click="switchSelected()"
        class="btn btn-primary btn-block mtb-3"
        disabled
      >
        Working...
      </button>
      <button
        v-else
        @click="switchSelected()"
        class="btn btn-primary btn-block mtb-3"
      >
        Switch Selected Text
      </button>
    </div>
  </div>
</template>

<script>
import TenseSwitcherProvider from "../api/tenseSwitcherProvider.js";
import { wordHandlerMixin } from "./mixins/wordHandlerMixin.js";

async function switchTenseWithSentence(sentence) {
  let body = { sentence: sentence };

  const [data, err] = await TenseSwitcherProvider.getSentenceSwitched(body);

  if (err) {
    return;
  } else {
    return data["result"];
  }
}

export default {
  name: "TenseSwitcherOnlySelection",
  mixins: [wordHandlerMixin],
  data: function () {
    return {
      loading: false,
      error: false,
      //text_area_value: "This will be an example",
      text_area_value: null,
      text_area_value_transformed: null,
      textToTransform: "   ",
      textTransformed: "   ",
    };
  },
  methods: {
    async switchTenseIntoTextArea() {
      this.text_area_value_transformed = "";
      this.error = false;
      this.errorMsg = "";

      this.switchTense().then((result) => {
        this.text_area_value_transformed = result;
      });
    },

    async switchTense() {
      let sentence = this.text_area_value;
      let body = { sentence: sentence };
      const [data, err] = await TenseSwitcherProvider.getSentenceSwitched(body);

      if (err) {
        console.log("Error:" + err);
        this.errorMsg = err;
        this.error = true;
        return;
      } else {
        return data["result"];
      }
    },

    async switchSelected() {
      this.loading = true;
      this.textTransformed = "   ";
      this.textToTransform = "   ";
      this.pasteIntoWordSelected();
    },
    async pasteFromTextArea() {
      let value = this.text_area_value_transformed;
      this.pasteIntoWordAtCursor(value);
    },
    async pasteIntoWordSelected() {

      return Word.run(async (context) => {
        var self = this;
        Office.context.document.getSelectedDataAsync(
          Office.CoercionType.Ooxml,
          function (asyncResult) {
            if (asyncResult.status == Office.AsyncResultStatus.Failed) {
              console.log("Action failed. Error: " + asyncResult.error.message);
            } else {
              self.textToTransform = asyncResult.value;
              //handleSelectedTextHTML(asyncResult.value, self);
              handleSelectedTextOoxml(asyncResult.value, self);
            }
          }
        );

        function handleSelectedTextOoxml(selectedTextFromWord, self) {
          try {
            let startTag = "<w:body>";
            let endTag = "</w:body>";

            var xmlBefore = selectedTextFromWord.split(startTag)[0] + startTag;
            var xmlAfter = endTag + selectedTextFromWord.split(endTag)[1];

            var selectedText = selectedTextFromWord
              .split(startTag)[1]
              .split(endTag)[0];

            self.textToTransform = selectedText;


            //self.textToTransform = selectedText;
          } catch (err) {
            console.log("Error in regexp:" + err);
          }

          try {
            switchTenseWithSentence(selectedText).then((result) => {
              self.textTransformed = result;
              if (!result) {
                self.textTransformed = "Error! Try with another text";
              } else {
                let totalResult = xmlBefore + result + xmlAfter;
                self.pasteReplacingSelectionIntoWord(totalResult);
              }
            });
          } catch(e) {
            console.log("Error while calling to switchTenseWithSentence:" + e);
          } finally {
          }
        }

        function handleSelectedTextHTML(selectedTextFromWord, self) {
          try {
            const pattern = /<body[^>]*>((.|[\n\r])*)<\/body>/im;

            var match = pattern.exec(selectedTextFromWord);

            var selectedText = match[1];

            self.textToTransform = selectedText;
          } catch (err) {
            console.log("Error in regexp:" + err);
          }

          try {
            switchTenseWithSentence(selectedText).then((result) => {
              self.textTransformed = result;
              self.pasteReplacingSelectionIntoWord(result);
            });
          } catch(e) {
            console.error(e);
          } finally {
          }
        }
      });
    },

    async pasteReplacingSelectionIntoWord(textoToPaste) {
      return Word.run(async (context) => {
        let cursorOrSelection = context.document.getSelection();
        context.load(cursorOrSelection);
        await context.sync();
        cursorOrSelection.insertOoxml(
          textoToPaste,
          Word.InsertLocation.replace
        );

        try {
          await context.sync();
        } catch (e) {
          console.log(e);
        } finally {
          this.loading = false;
        }

      });
    },
  },
};
</script>
<style scoped>
#textToTransformFromWord {
}

#textTransformedToWord {
}

.text-area {
  border: 1px solid black;
  text-align: left !important;

  margin-top: 1em;
  height: 7em;
  overflow-y: scroll;
}

.nrtlabel {
  font-size: 0.7em;
}
</style>