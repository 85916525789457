<template>
  <div>
    <h1
      class="h5 font-weight-normal mb-2 text-capitalize"
      v-if="workspace && workspace.name"
    >
      {{ workspace.name }}
    </h1>
    <!--
    No description available yet!
    <p class="font-italic" v-if="workspace.description">{{ workspace.description }}</p>
    -->
    

    <div class="row">
      <div class="col">
        <div class="input-group mb-3" v-if="this.showUploadDialog">
          <div class="custom-file">
            <input
              type="file"
              name="the_file"
              id="the_file"
              multiple="multiple"
              @change="onFileChange"
              class="btn-sm"
            />

            <label class="custom-file-label" for="the_file" id="files_label"
              ><span
                v-if="
                  !this.selectedFiles.length || this.selectedFiles.length == 0
                "
                >Choose files</span
              >
              <span v-else
                >{{ this.selectedFiles.length }}
                <span v-if="this.selectedFiles.length == 1">file</span
                ><span v-else>files</span></span
              >
            </label>
          </div>
          <div class="input-group-append">
            <span
              class="input-group-text"
              :disabled="!this.selectedFiles"
              @click="onUploadFiles"
              id="uploadFilesButton"
              >Upload</span
            >
          </div>
        </div>
        <button
          class="btn btn-sm mb-2 btn-success"
          @click="showTheUploadDialog()"
          id="uploadFiles"
          v-if="!this.showUploadDialog"
        >
          + Upload Files
        </button>

        <table
          class="table table-striped table-sm"
          style="table-layout: fixed"
          v-if="
            this.list_of_files &&
            this.list_of_files.files &&
            this.list_of_files.files.length &&
            this.list_of_files.files.length > 0
          "
        >
          <WorkspaceFileRemoveButton v-for="(item, key) in this.list_of_files.files" :key="key"                 :fileToDelete="item"
                :workspaceId="$route.params.workspaceId"
                :userId="$store.getters.user.id"
                @onDelete="deleteFile">
          </WorkspaceFileRemoveButton>
        </table>
        <div v-else-if="!this.loading" class="mt-2 mb-4">
          No files found. Upload to start working
        </div>
      </div>
    </div>


    <div v-if="workspace && workspace.name">
      <h1 class="h5 font-weight-normal mb-2 text-capitalize mt-4" id="operations_available">
          Operations Available
        </h1>
      <div
        class="row"
        v-for="operation in workspace.operations_available"
        :key="operation.id + '_' + randomString"
      >
        <div class="col">
          <div
            v-if="
              operation.elegible_for_operation &&
              operation.id == '10.1.csr_sec10_demographics'
            "
          >
            <CSRSec10DemographicsNarrativesWorkspace
              :workspaceId="$route.params.workspaceId"
              @refreshFiles="reloadFiles"
            >
            </CSRSec10DemographicsNarrativesWorkspace>
          </div>
          <div
            v-if="
              operation.elegible_for_operation &&
              operation.id == '10.2.csr_sec10_disposition'
            "
          >
            <CSRSec10DispositionNarrativesWorkspace
              :workspaceId="$route.params.workspaceId"
              @refreshFiles="reloadFiles"
            >
            </CSRSec10DispositionNarrativesWorkspace>
          </div>
          <div
            v-if="
              operation.elegible_for_operation &&
              operation.id == '10.3.csr_sec10_deviations'
            "
          >
            <CSRSec10DeviationsNarrativesWorkspace
              :workspaceId="$route.params.workspaceId"
              @refreshFiles="reloadFiles"
            >
            </CSRSec10DeviationsNarrativesWorkspace>
          </div>
          <div
            v-if="
              operation.elegible_for_operation &&
              operation.id == '12.1_12.2_12.3_adverse_events'
            "
          >
            <CSRSec12SafetyAdverseEventsNarrativesWorkspace
              :workspaceId="$route.params.workspaceId"
              @refreshFiles="reloadFiles"
            >
            </CSRSec12SafetyAdverseEventsNarrativesWorkspace>
          </div>
          <div
            v-if="
              operation.elegible_for_operation &&
              operation.id == '12.4.csr_sec12_sdtm_safety_lab'
            "
          >
            <CSRSec12SafetyLabNarrativesWorkspace
              :workspaceId="$route.params.workspaceId"
              @refreshFiles="reloadFiles"
            >
            </CSRSec12SafetyLabNarrativesWorkspace>
          </div>
          <div
            v-if="
              operation.elegible_for_operation &&
              operation.id == '12.5.csr_sec12_sdtm_safety_other_findings'
            "
          >
            <CSRSec12SafetyOtherFindingsNarrativesWorkspace
              :workspaceId="$route.params.workspaceId"
              @refreshFiles="reloadFiles"
            >
            </CSRSec12SafetyOtherFindingsNarrativesWorkspace>
          </div>
          <div
            v-if="
              operation.elegible_for_operation &&
              operation.id == 'patient_narratives'
            "
          >
            <PatientNarrativesWorkspace
              :workspaceId="$route.params.workspaceId"
              @refreshFiles="reloadFiles"
            >
            </PatientNarrativesWorkspace>
          </div>
          <div
            v-if="
              operation.elegible_for_operation &&
              operation.id == 'csr_narratives'
            "
          >
            <CSRNarrativesWorkspace
              :workspaceId="$route.params.workspaceId"
              @refreshFiles="reloadFiles"
            >
            </CSRNarrativesWorkspace>
          </div>
        </div>
      </div>
    </div>
    <!-- row -->
    <div
      class="row mt-2"
      v-if="
        this.list_of_files &&
        this.list_of_files.results &&
        this.list_of_files.results.length &&
        this.list_of_files.results.length > 0
      "
    >
      <div class="col">
        <h1 class="h5 font-weight-normal mb-2 text-capitalize mt-4" id="docs_generated">
          Documents Generated
        </h1>
        <div class="text-right">
        <button
          class="btn btn-sm mb-2 btn-success"
          @click="reloadFiles"
          id="reloadFiles"
        >
          Reload List
        </button>
        </div>
        <p v-if="loadingResult" class="text-center">Loading...</p>
        <table class="table table-striped table-sm" style="table-layout: fixed">
          <WorkspaceFileResultRemoveButton v-for="(item, key) in this.list_of_files.results" :key="key"                 :fileToDelete="item"
                :workspaceId="$route.params.workspaceId"
                :userId="$store.getters.user.id"
                @onDelete="deleteFile"
                @onPasteContentIntoWordAtCursor="pasteContentIntoWordAtCursor"
                @onDownloadResultFile="downloadResultFile"
                >
          </WorkspaceFileResultRemoveButton>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import WorkspacesProvider from "../api/workspacesProvider.js";
import { wordHandlerMixin } from "./mixins/wordHandlerMixin.js";
import Files from "../api/files.js";
import PatientNarrativesWorkspace from "./PatientNarrativesWorkspace.vue";
import CSRNarrativesWorkspace from "./CSRNarrativesWorkspace.vue";
import WorkspaceFileRemoveButton from "./WorkspaceFileRemoveButton.vue";
import WorkspaceFileResultRemoveButton from "./WorkspaceFileResultRemoveButton.vue";
import CSRSec10DemographicsNarrativesWorkspace from "./CSRSec10DemographicsNarrativesWorkspace.vue";
import CSRSec10DispositionNarrativesWorkspace from "./CSRSec10DispositionNarrativesWorkspace.vue";
import CSRSec10DeviationsNarrativesWorkspace from "./CSRSec10DeviationsNarrativesWorkspace.vue";
import CSRSec12SafetyOtherFindingsNarrativesWorkspace from "./CSRSec12SafetyOtherFindingsNarrativesWorkspace.vue";
import CSRSec12SafetyLabNarrativesWorkspace from "./CSRSec12SafetyLabNarrativesWorkspace.vue";
import CSRSec12SafetyAdverseEventsNarrativesWorkspace from "./CSRSec12SafetyAdverseEventsNarrativesWorkspace.vue";

export default {
  name: "Workspace",
  mixins: [wordHandlerMixin],
  components: {
    PatientNarrativesWorkspace,
    CSRNarrativesWorkspace,
    CSRSec10DispositionNarrativesWorkspace,
    CSRSec10DemographicsNarrativesWorkspace,
    CSRSec10DeviationsNarrativesWorkspace,
    CSRSec12SafetyAdverseEventsNarrativesWorkspace,
    CSRSec12SafetyLabNarrativesWorkspace,
    CSRSec12SafetyOtherFindingsNarrativesWorkspace,
    WorkspaceFileRemoveButton,
    WorkspaceFileResultRemoveButton
  },

  data: function () {
    return {
      loading: false,
      loadingResult: false,
      error: false,
      workspace: null,
      newWorkspaceName: "",
      list_of_files: null,
      showUploadDialog: false,
      selectedFiles: [],
      showButtons: true,
      //to force the reload of buttons. Make it better
      randomString: "",
    };
  },
  mounted() {
    this.workspace = this.getWorkspace();
    this.list_of_files = this.getFiles();
  },
  methods: {
    showTheUploadDialog() {
      this.showUploadDialog = !this.showUploadDialog;
    },

    async reloadFiles() {
      this.list_of_files = await this.getFiles();
    },
    async getFiles() {
      this.loading = true;
      this.loadingResult = true;

      console.log("getFiles()");

      const [data, err] = await Files.getListOfFilesForWorkspaceAndUser(
        this.$store.getters.user.id,
        this.$route.params.workspaceId
      );

      if (err) {
        console.log("Error:" + err);
        this.errorMsg = err;
        this.error = true;
        this.loading = false;
        this.loadingResult= false;
        return;
      } else {
        console.log("get files finished!:" + data["result"]);
        this.workspace = await this.getWorkspace();
        this.list_of_files = data;
        this.loading = false;
        this.loadingResult= false;
        return data;
      }
    },
    async getWorkspace() {
      this.loading = true;
      this.loadingResult = true;

      const [data, err] = await WorkspacesProvider.getWorkspaceById(
        this.$store.getters.user.id,
        this.$route.params.workspaceId
      );

      if (err) {
        console.log("Error getWorkspaceById:" + err);
        this.loading = false;
        this.loadingResult = false;
        return;
      } else {
        this.workspace = data;
        console.log("getWorkspaceById ok");
        this.loading = false;
        this.loadingResult = false;
        return data;
      }
    },
    onFileChange(e) {
      const selectedFiles = e.target.files; // accessing file
      this.selectedFiles = selectedFiles;
      console.log("onFileChange: " + this.selectedFiles);
    },
    async onUploadFiles() {
      this.loading = true;
      const formData = new FormData();
      this.selectedFiles.forEach((element) => {
        formData.append("the_file", element);
      });

      const [data, err] = await Files.uploadFilesForWorkspaceAndUser(
        this.$store.getters.user.id,
        this.$route.params.workspaceId,
        formData
      );

      if (err) {
        console.log("Error onUploadFiles:" + err);
        this.loading = false;
        return;
      } else {
        this.workspace = data;
        console.log("onUploadFiles ok");
        this.loading = false;
        console.log("trying to reload files with getFiles()");
        this.list_of_files = await this.getFiles();
        return data;
      }
    },
    async deleteFile(fileName) {
      this.loading = true;
      const [data, err] = await Files.deleteFiles(
        this.$store.getters.user.id,
        this.$route.params.workspaceId,
        fileName
      );

      if (err) {
        console.log("Error deleteFile:" + err);
        console.log(data);
        this.loading = false;
        return;
      }
      console.log("deleteFile ok");
      this.list_of_files = await this.getFiles();
      return;
    },
    async creteNewWordDoc() {
      return Word.run(async (context) => {
        console.log("creteNewWordDoc");
        var myNewDoc = context.application.createDocument();
        context.load(myNewDoc);
        var body = myNewDoc.body;
        body.insertText("some text", Word.InsertLocation.end);
        await context.sync();
        myNewDoc.open();
      });
    },

    async downloadResultFile(fileName) {
      this.loadingResult = true;
      const [data, err] = await Files.downloadFile(
        this.$store.getters.user.id,
        this.$route.params.workspaceId,
        fileName
      );

      if (err) {
        console.log("Error downloadResultFile:" + err);
        console.log(data);
        this.loading = false;
        return;
      }

      this.pasteIntoNewWordDocument(data, fileName);
      this.loadingResult = false;

      return;
    },

    async pasteContentIntoWordAtCursor(fileName) {
      this.loadingResult = true;
      const [data, err] = await Files.downloadFile(
        this.$store.getters.user.id,
        this.$route.params.workspaceId,
        fileName
      );

      if (err) {
        console.log("Error downloadResultFile:" + err);
        console.log(data);
        this.loading = false;
        return;
      }

      this.pasteIntoWordAtCursor(data, fileName);
      this.loadingResult = false;

      return;
    },
  },
};
</script>
<style scoped>
.nrtlabel {
  font-size: 0.7em;
}

#uploadFilesButton {
  z-index: 9999;
  background-color: #218838;
  color: white;
  cursor: pointer;
  font-size: 0.875rem;
}

#the_file {
  color: transparent;
}

.custom-file {
  font-size: 0.875rem;
}
</style>