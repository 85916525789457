<template>
  <div>
    <button
      @click="getCSRNarrativesOtherFindings()"
      class="btn btn-primary btn-block mtb-3 mb-4 btn-sm"
      :disabled="loading"
    >
      <span v-if="!loading">Generate 12.1, 12.2 and 12.3 sections</span>
      <span v-else>Working... this might take a while</span>
    </button>
    <p v-if="this.errorMsg" class="text-center">Error: {{ this.errorMsg }}</p>
  </div>
</template>

<script>
import CSRSec12NarrativesProvider from "../api/csrSec12NarrativesProvider.js";
import { wordHandlerMixin } from "./mixins/wordHandlerMixin.js";

export default {
  name: "CSRSec12SafetyAdverseEventsNarrativesWorkspace",
  mixins: [wordHandlerMixin],
  data: function () {
    return {
      loading: false,
      error: false,
      errorMsg: "",
      csrNarratives: null,
    };
  },
  props: {
    workspaceId: String,
  },
  mounted() {
    //this.csrNarratives = this.getCSRNarratives();
  },
  methods: {
    async getCSRNarrativesOtherFindings() {
      this.loading = true;
      this.error = false;
      this.errorMsg = "";
      const [data, err] =
        await CSRSec12NarrativesProvider.getCSRNarrativesSafetyAdverseEventsForWorkspace(
          this.$store.getters.user.id,
          this.$route.params.workspaceId
        );

      if (err) {
        console.log("Error getCSRNarratives:" + err.data.error);
        this.errorMsg = err.data.error;
        this.csrNarratives = null;
        this.error = true;
        this.loading = false;
        return;
      } else {
        this.csrNarratives = data;
        console.log("CSR narratives asigned");
        this.loading = false;
        setTimeout(() => {
          this.$emit("refreshFiles", 1);
        }, 100);

        return data["result"];
      }
    },
  },
};
</script>
<style scoped>
.text-area {
  border: 1px solid black;
  text-align: left !important;

  margin-top: 1em;
  height: 7em;
  overflow-y: scroll;
  background-color: white;
  font-size: 0.75em;
}

.nrtlabel {
  font-size: 0.7em;
}
</style>