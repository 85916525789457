import Endpoint from './endpoint'
import bindAll from 'lodash.bindall'

class CSRNarrativesProvider extends Endpoint {
  constructor() {
    let url = 'api/csr';
   
    super(url);
    bindAll(this, ['getCSRNarratives','getCSRNarrativesForWorkspace']);
  }
  getCSRNarratives(sectionId) {
    return this.get(`/${sectionId}`);
  }

  getCSRNarrativesForWorkspace(userId,workspaceId) {
    return this.get(`/users/${userId}/workspaces/${workspaceId}`);
  }

  
}


export default new CSRNarrativesProvider()