<template>
  <div>
    <h4>Patience Narratives</h4>

    <button
      @click="getPatientNarratives()"
      class="btn btn-primary btn-sm mtb-3 mb-4"
    >
      Download Patient Narratives
    </button>
    <p v-if="loading" class="text-center">Working... this might take a while</p>

    <div v-for="(item, key) in this.patientNarratives" :key="key" class="mb-4">
      <h5>Subject: {{ item.subject }}</h5>
      <p class="text-area">
        <span v-html="item.narrative"></span>
      </p>
      <button
        @click="pasteIntoWordAtCursor(item.narrative)"
        class="btn btn-primary btn-sm"
      >
        Paste at cursor
      </button>
    </div>
  </div>
</template>

<script>
import PatientNarrativesProvider from "../api/patientNarrativesProvider.js";
import { wordHandlerMixin } from "./mixins/wordHandlerMixin.js";

export default {
  name: "PatientNarratives",
  mixins: [wordHandlerMixin],
  data: function () {
    return {
      loading: false,
      error: false,
      patientNarratives: null,
    };
  },
  mounted() {
    //this.patientNarratives = this.getPatientNarratives();
  },
  methods: {
    async getPatientNarratives() {
      this.loading = true;
      let sentence = this.text_area_value;
      let body = { sentence: sentence };
      const [data, err] = await PatientNarrativesProvider.getPatientNarratives(
        "123"
      );

      if (err) {
        console.log("Error getPatientNarratives:" + err);
        this.errorMsg = err;
        this.error = true;
        this.loading = false;
        return;
      } else {
        this.patientNarratives = data;
        console.log("Patient narratives asigned");
        this.loading = false;
        return data["result"];
      }
    },
  },
};
</script>
<style scoped>
.text-area {
  border: 1px solid black;
  text-align: left !important;

  margin-top: 1em;
  height: 7em;
  overflow-y: scroll;
  background-color: white;
}

.nrtlabel {
  font-size: 0.7em;
}
</style>