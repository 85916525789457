import Vue from 'vue';
import App from './App.vue';
import store from "./store";
import axios from "axios";
import router from "./router";

Vue.config.productionTip = false;

import './assets/css/bootstrap.css'
import './assets/css/custom.css'
import './assets/font-awesome/css/font-awesome.min.css'

window.Office.initialize = () => {
  const TheApp = new Vue({
    render: h => h(App),
    router,
    store,
    axios,
  });

  /* Axios interceptor to send to login when get unauthoriez response */
  const UNAUTHORIZED = 401;
  axios.interceptors.response.use(
    response => response,
    error => {

      const { status } = error.response;
      if (status === UNAUTHORIZED) {
        console.log("Unathorized call to API. Redirect to Login")
        if (error.response.data.redirect === "false") {

          return Promise.reject(error);
        }
        const unathorized_retry = localStorage.getItem('unathorized_retry');

        if (!unathorized_retry) {

          store.dispatch('logOutUser')
          router.push('/login')
        }
        localStorage.removeItem('unathorized_retry')

      }
      return Promise.reject(error);
    }
  );

  Vue.prototype.axios = axios

  TheApp.$mount('#app');
};
