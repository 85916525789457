export default {
    ['LOGGED_IN'](state, data) {
      const { user } = data
  
      state.session.isLoggedIn = true
      state.session.user = user
  
      localStorage.setItem('token', data.token)
      localStorage.setItem('user', JSON.stringify(user))
      localStorage.setItem('refresh', data.refresh)
    },
  
    ['LOGGED_OUT'](state) {
      localStorage.removeItem('token')
      localStorage.removeItem('user')
      localStorage.removeItem('refresh')
      localStorage.removeItem('unathorized_retry')

      state.session.isLoggedIn = false
      state.session.user = null
    },
  }