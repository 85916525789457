import Endpoint from './endpoint'
import bindAll from 'lodash.bindall'

class TenseSwitcherProvider extends Endpoint {
  constructor() {
    let url = 'api/clinical_trials_fake';
   
    super(url);
    bindAll(this, ['getClinicalTrials']);
  }
  getClinicalTrials(sectionId, body) {
    return this.post(`/${sectionId}`,body);
  }

  
}


export default new TenseSwitcherProvider()