<template>
  <nav class="navbar navbar-dark bg-dark" id="nrt_navbar">
    <a class="navbar-brand" href="#">
      <img
        alt="Narrativa Logo"
        src="../assets/nrt_logo.png"
        width="30"
        height="30"
        class="d-inline-block align-top"
      />
      <!--Bootstrap-->
    </a>
    <span class="loginHeader">AI Assisted Medical Writing</span>
    <button
      class="navbar-toggler collapsed"
      type="button"
      data-toggle="collapse"
      data-target="#navbarsExample01"
      aria-controls="navbarsExample01"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="navbar-collapse collapse" id="navbarsExample01" style="">
      
      <ul class="navbar-nav mr-auto">
        <li class="nav-item" v-if="userName">
          <router-link
            to="/operations"
            class="nav-link"
            data-toggle="collapse"
            data-target=".navbar-collapse.show"
            >Tools</router-link
          >
        </li>
        <!--
        <li class="nav-item">
          <router-link
            to="/patientNarratives"
            class="nav-link"
            data-toggle="collapse"
            data-target=".navbar-collapse.show"
            >Patient Narratives</router-link
          >
        </li>-->
        <!--<li class="nav-item">
          <router-link
            to="/clinicalTrials"
            class="nav-link"
            data-toggle="collapse"
            data-target=".navbar-collapse.show"
            >Clinical Trials</router-link
          >
        </li>-->
        <!--<li class="nav-item">
          <router-link
            to="/tenseSwitcher"
            class="nav-link"
            data-toggle="collapse"
            data-target=".navbar-collapse.show"
            >Tense Switcher</router-link
          >
        </li>
        <li class="nav-item">
          <router-link
            to="/football"
            class="nav-link"
            data-toggle="collapse"
            data-target=".navbar-collapse.show"
            >Content to Paste</router-link
          >
        </li>-->

        <!--<li class="nav-item dropdown">
          <a
            class="nav-link dropdown-toggle"
            href="#"
            id="dropdown01"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            >Dropdown</a
          >
          <div class="dropdown-menu" aria-labelledby="dropdown01">
            <a class="dropdown-item" href="#">Action</a>
            <a class="dropdown-item" href="#">Another action</a>
            <a class="dropdown-item" href="#">Something else here</a>
          </div>
        </li>-->
        <!--<li class="nav-item">
          <router-link
            to="/help"
            class="nav-link"
            data-toggle="collapse"
            data-target=".navbar-collapse.show"
            >Help</router-link
          >
        </li>-->
        <li class="nav-item">
          <a v-if="userName"
            href="#"
            class="nav-link"
            data-toggle="collapse"
            data-target=".navbar-collapse.show"
            @click="logout"
            >Log out ({{ userName }})</a
          >
          <a v-else
            href="#"
            class="nav-link"
            data-toggle="collapse"
            data-target=".navbar-collapse.show"
            @click="$router.push('/')"
            >Log in</a
          >
        </li>
      </ul>
      <!--<form class="form-inline my-2 my-md-0">
          <input
            class="form-control"
            type="text"
            placeholder="Search"
            aria-label="Search"
          />
        </form>-->
    </div>
  </nav>
</template>

<script>
export default {
  computed: {
    userName() {
      if (this.$store.getters.isLoggedIn){
        return this.$store.getters.user.name;
      }
      return "";
    },
    isLoggedIn(){
      return this.$store.getters.isLoggedIn;
    }
  },
  methods: {
    logout(e) {
      e.preventDefault();
      console.log("Log out")
      this.$store.dispatch("logOutUser");
      this.$router.push("/login");
    },
  },
};
</script>

<style scoped>
.loginHeader{
  color:white;
  
}
</style>