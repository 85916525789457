var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.workspace && _vm.workspace.name)?_c('h1',{staticClass:"h5 font-weight-normal mb-2 text-capitalize"},[_vm._v(" "+_vm._s(_vm.workspace.name)+" ")]):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[(this.showUploadDialog)?_c('div',{staticClass:"input-group mb-3"},[_c('div',{staticClass:"custom-file"},[_c('input',{staticClass:"btn-sm",attrs:{"type":"file","name":"the_file","id":"the_file","multiple":"multiple"},on:{"change":_vm.onFileChange}}),_c('label',{staticClass:"custom-file-label",attrs:{"for":"the_file","id":"files_label"}},[(
                !this.selectedFiles.length || this.selectedFiles.length == 0
              )?_c('span',[_vm._v("Choose files")]):_c('span',[_vm._v(_vm._s(this.selectedFiles.length)+" "),(this.selectedFiles.length == 1)?_c('span',[_vm._v("file")]):_c('span',[_vm._v("files")])])])]),_c('div',{staticClass:"input-group-append"},[_c('span',{staticClass:"input-group-text",attrs:{"disabled":!this.selectedFiles,"id":"uploadFilesButton"},on:{"click":_vm.onUploadFiles}},[_vm._v("Upload")])])]):_vm._e(),(!this.showUploadDialog)?_c('button',{staticClass:"btn btn-sm mb-2 btn-success",attrs:{"id":"uploadFiles"},on:{"click":function($event){return _vm.showTheUploadDialog()}}},[_vm._v(" + Upload Files ")]):_vm._e(),(
          this.list_of_files &&
          this.list_of_files.files &&
          this.list_of_files.files.length &&
          this.list_of_files.files.length > 0
        )?_c('table',{staticClass:"table table-striped table-sm",staticStyle:{"table-layout":"fixed"}},_vm._l((this.list_of_files.files),function(item,key){return _c('WorkspaceFileRemoveButton',{key:key,attrs:{"fileToDelete":item,"workspaceId":_vm.$route.params.workspaceId,"userId":_vm.$store.getters.user.id},on:{"onDelete":_vm.deleteFile}})}),1):(!this.loading)?_c('div',{staticClass:"mt-2 mb-4"},[_vm._v(" No files found. Upload to start working ")]):_vm._e()])]),(_vm.workspace && _vm.workspace.name)?_c('div',[_c('h1',{staticClass:"h5 font-weight-normal mb-2 text-capitalize mt-4",attrs:{"id":"operations_available"}},[_vm._v(" Operations Available ")]),_vm._l((_vm.workspace.operations_available),function(operation){return _c('div',{key:operation.id + '_' + _vm.randomString,staticClass:"row"},[_c('div',{staticClass:"col"},[(
            operation.elegible_for_operation &&
            operation.id == '10.1.csr_sec10_demographics'
          )?_c('div',[_c('CSRSec10DemographicsNarrativesWorkspace',{attrs:{"workspaceId":_vm.$route.params.workspaceId},on:{"refreshFiles":_vm.reloadFiles}})],1):_vm._e(),(
            operation.elegible_for_operation &&
            operation.id == '10.2.csr_sec10_disposition'
          )?_c('div',[_c('CSRSec10DispositionNarrativesWorkspace',{attrs:{"workspaceId":_vm.$route.params.workspaceId},on:{"refreshFiles":_vm.reloadFiles}})],1):_vm._e(),(
            operation.elegible_for_operation &&
            operation.id == '10.3.csr_sec10_deviations'
          )?_c('div',[_c('CSRSec10DeviationsNarrativesWorkspace',{attrs:{"workspaceId":_vm.$route.params.workspaceId},on:{"refreshFiles":_vm.reloadFiles}})],1):_vm._e(),(
            operation.elegible_for_operation &&
            operation.id == '12.1_12.2_12.3_adverse_events'
          )?_c('div',[_c('CSRSec12SafetyAdverseEventsNarrativesWorkspace',{attrs:{"workspaceId":_vm.$route.params.workspaceId},on:{"refreshFiles":_vm.reloadFiles}})],1):_vm._e(),(
            operation.elegible_for_operation &&
            operation.id == '12.4.csr_sec12_sdtm_safety_lab'
          )?_c('div',[_c('CSRSec12SafetyLabNarrativesWorkspace',{attrs:{"workspaceId":_vm.$route.params.workspaceId},on:{"refreshFiles":_vm.reloadFiles}})],1):_vm._e(),(
            operation.elegible_for_operation &&
            operation.id == '12.5.csr_sec12_sdtm_safety_other_findings'
          )?_c('div',[_c('CSRSec12SafetyOtherFindingsNarrativesWorkspace',{attrs:{"workspaceId":_vm.$route.params.workspaceId},on:{"refreshFiles":_vm.reloadFiles}})],1):_vm._e(),(
            operation.elegible_for_operation &&
            operation.id == 'patient_narratives'
          )?_c('div',[_c('PatientNarrativesWorkspace',{attrs:{"workspaceId":_vm.$route.params.workspaceId},on:{"refreshFiles":_vm.reloadFiles}})],1):_vm._e(),(
            operation.elegible_for_operation &&
            operation.id == 'csr_narratives'
          )?_c('div',[_c('CSRNarrativesWorkspace',{attrs:{"workspaceId":_vm.$route.params.workspaceId},on:{"refreshFiles":_vm.reloadFiles}})],1):_vm._e()])])})],2):_vm._e(),(
      this.list_of_files &&
      this.list_of_files.results &&
      this.list_of_files.results.length &&
      this.list_of_files.results.length > 0
    )?_c('div',{staticClass:"row mt-2"},[_c('div',{staticClass:"col"},[_c('h1',{staticClass:"h5 font-weight-normal mb-2 text-capitalize mt-4",attrs:{"id":"docs_generated"}},[_vm._v(" Documents Generated ")]),_c('div',{staticClass:"text-right"},[_c('button',{staticClass:"btn btn-sm mb-2 btn-success",attrs:{"id":"reloadFiles"},on:{"click":_vm.reloadFiles}},[_vm._v(" Reload List ")])]),(_vm.loadingResult)?_c('p',{staticClass:"text-center"},[_vm._v("Loading...")]):_vm._e(),_c('table',{staticClass:"table table-striped table-sm",staticStyle:{"table-layout":"fixed"}},_vm._l((this.list_of_files.results),function(item,key){return _c('WorkspaceFileResultRemoveButton',{key:key,attrs:{"fileToDelete":item,"workspaceId":_vm.$route.params.workspaceId,"userId":_vm.$store.getters.user.id},on:{"onDelete":_vm.deleteFile,"onPasteContentIntoWordAtCursor":_vm.pasteContentIntoWordAtCursor,"onDownloadResultFile":_vm.downloadResultFile}})}),1)])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }