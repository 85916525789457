export const wordHandlerMixin = {
  methods: {
    async pasteIntoWordAtCursor(textoToPaste) {
      return Word.run(async (context) => {
        let cursorOrSelection = context.document.getSelection();
        context.load(cursorOrSelection);
        await context.sync();
        cursorOrSelection.insertHtml(textoToPaste, Word.InsertLocation.replace);
        await context.sync();
      });
    },
    async pasteIntoNewWordDocument(textoToPaste, docName) {

      return Word.run(async (context) => {
        console.log("creteNewWordDoc")
        var myNewDoc = context.application.createDocument();
        context.load(myNewDoc);
        var body = myNewDoc.body;

        body.insertHtml(textoToPaste, Word.InsertLocation.start);
        await context.sync();
        myNewDoc.open();
      });










      return Word.run(async (context) => {
        var myNewDoc = context.application.createDocument();
        context.load(myNewDoc);

        //this is OK
        //myNewDoc.properties.customProperties.add("code", "600000");

        // this is NG
        //        var range = myNewDoc.getSelection();
        //        range.insertText("123", Word.InsertLocation.start);

        await context.sync();
        myNewDoc.open();
        await context.sync();
        console.log("Trying to paste:" + textoToPaste)
        let cursorOrSelection = context.document.getSelection();
        context.load(cursorOrSelection);
        await context.sync();
        cursorOrSelection.insertHtml(textoToPaste, Word.InsertLocation.start);
        await context.sync();

        return context.sync().then(function () {
          console.log("finished");
        }).catch(function (e) {
          console.log(e);
        });
      });
    }
  }
};