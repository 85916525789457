import axios from 'axios'


//Warning: this must be changed
const API_HOST = process.env.VUE_APP_API_HOST;
console.debug("API_HOST: " + API_HOST)

export default class Endpoint {
  constructor(endpoint) {
    this.fetch = this.fetch.bind(this)
    this.endpoint = `${API_HOST}/${endpoint}`
  }

  _addTimeStampToUrl(url) {
    let nowMark = `t=${Date.now()}`;

    if (url.includes('?')){
      return url + '&'+nowMark;
    }
    return url + '?'+nowMark;
  }

  async fetch(node, method = 'get', data = null, conf = {}) {
    let endpoint = this.endpoint + node;
    endpoint = this._addTimeStampToUrl(endpoint);

    //const endpoint = this.endpoint + node

    const config = Object.assign({}, {
      url: endpoint,
      method: method
    }, conf)

    if (method == 'get' && data) {
      config['params'] = data
    }

    if (method != 'get') {
      config['data'] = data
    }

    const token = localStorage.getItem('token')

    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }

    if (token) {
      headers['Authorization'] = `Bearer ${token}`
    }

    config['headers'] = headers

    /* this that go style request */

    const refresh_token = localStorage.getItem('refresh');
    if (refresh_token) {
      localStorage.setItem('unathorized_retry', "true")
    }
    try {
      const response = await axios.request(config)
      localStorage.removeItem('unathorized_retry')
      return [response.data, null]
    } catch (err) {
      localStorage.removeItem('unathorized_retry')
      console.log(`Error occured on ${endpoint}`)

      console.log(err.status)

      if (refresh_token) {
        console.debug("There is refresh_token");
        const renewed_token = await this.refresh(refresh_token);

        if (renewed_token) {
          localStorage.setItem('token', renewed_token);

          headers['Authorization'] = `Bearer ${renewed_token}`
          config['headers'] = headers
          try {
            const response = await axios.request(config);
            return [response.data, null]
          } catch (err) {
            console.log(`Error occured on ${endpoint} after refresh token`)

            console.log(err.status)
            return [null, err.response ? err.response : err.message]
          }

        }
      }

      return [null, err.response ? err.response : err.message]
    }
  }

  async refresh(refresh_token) {
    console.log("Refreshing token");
    try {
      const response = await axios({
        method: 'post',
        url: `${API_HOST}/api/users/refresh`,
        data: {
          refresh_token: refresh_token,
        }
      })
      return response.data.access_token;
    } catch (error) {
      return "";
    }
  }

  async get(node = '', data) {
    data = data ? { params: data } : null
    return this.fetch(node, 'get', data)
  }

  async post(node = '', data) {
    console.log("Node:" + node);
    return this.fetch(node, 'post', data)
  }

  async put(node = '', data, config) {
    return this.fetch(node, 'put', data, config)
  }

  async patch(node = '', data) {
    return this.fetch(node, 'patch', data)
  }

  async delete(node = '') {
    return this.fetch(node, 'delete')
  }
}
