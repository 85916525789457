import Endpoint from './endpoint'
import bindAll from 'lodash.bindall'

class PatientNarrativesProvider extends Endpoint {
  constructor() {
    let url = 'api/patient_narratives';
    //let url = 'api/patient_narratives_fake';
   
    super(url);
    bindAll(this, ['getPatientNarratives','getPatientNarrativesForWorkspace']);
  }
  getPatientNarratives(sectionId) {
    return this.get(`/${sectionId}`);
  }

  getPatientNarrativesForWorkspace(userId,workspaceId) {
    return this.get(`/users/${userId}/workspaces/${workspaceId}`);
  }

  
}


export default new PatientNarrativesProvider()